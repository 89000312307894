import { createRouter as createVueRouter, createWebHistory } from 'vue-router'
import RouteMultiGuard from 'vue-router-multiguard'
import { Routes, RouteNames } from './Routes'
import { log, hasRole, isAuthenticated } from '@/router/middleware'
import homeIfAlreadyLoggedIn from './middleware/homeIfAlreadyLoggedIn'
import { useAuth0Store } from '@/stores/auth0Store'

import ProjectListView from '@/views/shared/projectList/index'
import ArtdirectorProjectView from '@/views/artdirector/project/index'
import ArtdirectorRevisionView from '@/views/artdirector/revision/index'
import ReviewReferenceImageView from '@/views/shared/reviewReferenceImage/index'
import AdminJobsView from '@/views/admin/jobs/index'
import AdminSettingsView from '@/views/admin/settings/index'
import AdminReportView from '@/views/admin/reports/index'
import AdminDashboardView from '@/views/admin/dashboard/index'
import QualityAssuranceDashboardView from '@/views/qualityAssurance/dashboard/index'
import QualityAssuranceReviewSelectionView from '@/views/qualityAssurance/reviewSelection/index'
import UnauthorizedView from '@/views/auth/unauthorized/index'
import PageNotFoundView from '@/views/pageNotFound/index'

import AuthCallbackView from '@/views/auth/callback/index'
import Signin from '@/views/auth/signin/index'

import ReshootVideo from '@/views/shared/ReshootImage/index'
import DownloadQueueView from '@/views/shared/downloadQueue/index'

export default createVueRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            name: Routes.Home,
            redirect: `/${Routes.Projects}`,
            beforeEnter: RouteMultiGuard([log,isAuthenticated]),
        },
        {
            path: '/login',
            name: Routes.SignIn,
            component: Signin,
            beforeEnter: RouteMultiGuard([log, homeIfAlreadyLoggedIn]),
        },
        {
            path: '/photographer',
            name: Routes.Photographer,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('photographer', 'admin')]),
        },
        {
            path: '/artdirector',
            name: Routes.ArtDirector,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('artdirector', 'admin')]),
        },
        {
            path: '/projects',
            name: Routes.Projects,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([
                log,
                isAuthenticated,
                hasRole('photographer', 'qualityassurance', 'artdirector', 'admin'),
            ]),
        },
        {
            path: '/artdirector/review-reference-image/:projectId',
            props: true,
            name: Routes.ArtDirectorReviewReferenceImage,
            component: ReviewReferenceImageView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('artdirector', 'admin')]),
        },

        {
            path: '/artdirector/project/:projectId',
            props: true,
            name: Routes.ArtDirectorProject,
            component: ArtdirectorProjectView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('artdirector', 'admin')]),
        },
        {
            path: '/artdirector/reshoot-video/:projectId',
            props: true,
            name: Routes.ArtDirectorReshootProject,
            component: ReshootVideo,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('artdirector', 'admin')]),
        },
        {
            path: '/photographer/project/:projectId',
            props: true,
            name: Routes.PhotographerProject,
            component: ArtdirectorProjectView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('photographer')]),
        },

        // temporary route
        {
            path: '/artdirector/project/:projectId/:newSubmission',
            props: true,
            name: Routes.ArtDirectorProjectSubmission,
            component: ArtdirectorProjectView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('artdirector', 'admin')]),
        },
        {
            path: '/artdirector/project/:projectId/revision/:revisionId',
            props: true,
            name: Routes.ArtDirectorRevision,
            component: ArtdirectorRevisionView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('artdirector', 'admin')]),
        },
        {
            path: '/admin/jobs',
            name: Routes.AdminJobs,
            component: AdminJobsView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('artdirector', 'admin', 'qualityassurance')]),
        },
        {
            path: '/admin/settings',
            name: Routes.AdminSettings,
            component: AdminSettingsView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('admin')]),
        },
        {
            path: '/admin/reports',
            name: Routes.AdminReports,
            component: AdminReportView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('admin', 'qualityassurance')]),
        },
        // TODO Check why it goes to unauthorised when loggin into qa
        {
            path: '/auth/unauthorized',
            name: Routes.Unauthorized,
            component: UnauthorizedView,
            beforeEnter: RouteMultiGuard([log, homeIfAlreadyLoggedIn]),
            props: true,
        },
        {
            path: '/auth/callback',
            name: Routes.AuthCallback,
            component: AuthCallbackView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, homeIfAlreadyLoggedIn]),
        },
        {
            path: '/quality-assurance',
            name: Routes.QualityAssurance,
            component: ProjectListView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('qualityassurance', 'admin')]),
        },
        {
            path: '/quality-assurance/dashboard',
            name: Routes.QualityAssuranceDashboard,
            component: QualityAssuranceDashboardView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('qualityassurance', 'admin')]),
        },
        {
            path: '/quality-assurance/review-reference-image/:projectId',
            props: true,
            name: Routes.QualityAssuranceReviewReferenceImage,
            component: ReviewReferenceImageView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('qualityassurance', 'admin')]),
        },
        {
            path: '/quality-assurance/selection/:projectId',
            props: true,
            name: Routes.QualityAssuranceReviewSelection,
            component: QualityAssuranceReviewSelectionView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('qualityassurance', 'admin')]),
        },
        {
            path: '/quality-assurance/reshoot-video/:projectId',
            props: true,
            name: Routes.QualityAssuranceReshootProject,
            component: ReshootVideo,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('qualityassurance', 'admin')]),
        },
        {
            path: '/admin/dashboard',
            name: Routes.AdminDashboard,
            component: AdminDashboardView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('admin')]),
        },
        {
            path: '/:catchAll(.*)*',
            name: Routes.NotFound,
            component: PageNotFoundView,
            beforeEnter: RouteMultiGuard([log]),
        },
        {
            path: '/download-queue',
            name: Routes.DownloadQueue,
            component: DownloadQueueView,
            beforeEnter: RouteMultiGuard([log, isAuthenticated, hasRole('admin')]),
        },
    ],
})

export { Routes, RouteNames }
