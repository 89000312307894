import { useAuth0Store } from '@/stores/auth0Store'
import { computed, unref } from 'vue'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { Routes } from '@/router/Routes'
import { CLUSTER } from '@/utils/NODE_ENV'

export default async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const auth0 = useAuth0Store()
    await auth0.getTokenSilently({
                authorizationParams: {
                    audience: `https://user-management-api.${CLUSTER}.inhausai-infra.services`
                }
            }) as string 
    const isAuthenticated = unref(auth0.isAuthenticated)
    if (isAuthenticated) {
        console.log(`isAuthenticated: ${isAuthenticated}`)
        return next()
    }

    return next({
        name: Routes.Unauthorized,
        state: { message: `You need to be authenticated to access ${to.fullPath}` },
    })
}
