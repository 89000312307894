import { ProjectApiClient, type IProject, type IRevision } from '@inhaus-ai-limited/project-api-sdk'
import { defineStore } from 'pinia'
import { createPaginationStore } from './generators/createPaginationStore'
import { useAuth0Store } from './auth0Store'
import {CLUSTER} from '@/utils/NODE_ENV'


// Project pagination store
export const useProjectPaginationStore = createPaginationStore<IProject>(
    'project',
    '_id',
    
    () => useAuth0Store().user,
    async (page, limit, ...args) => {
        const { getTokenSilently } = useAuth0Store()
        try {
            const token = await getTokenSilently({
                authorizationParams: {
                    audience: `https://project-api.${CLUSTER}.inhausai-infra.services`,
                },
            })
           
            const projectService = new ProjectApiClient({
                auth: {
                    accessToken: token as string,
                },
            })

            const { items, total } = await projectService.listProjects(page, limit, ...args)
            return { items, totalItems: total }
        } catch (error) {
            console.error('Error in useProjectPaginationStore:', error)
            return { items: [], totalItems: 0 }
        }
    }
)

// Project store
export const useProjectStore = defineStore('project',() => {
    // State
    let projectService: ProjectApiClient
    const projects = new Map<string, IProject>()
    const { getTokenSilently } = useAuth0Store()
    // Initialize the service
    const init = async () => {
        const token = await getTokenSilently({
            authorizationParams: {
                audience: `https://project-api.${CLUSTER}.inhausai-infra.services`,
            },
        })
        projectService = new ProjectApiClient({
            auth: {
                accessToken: token as string,
            },
        })
    }

  

    // Helper function to handle errors
    const handleError = (error: any, context: string) => {
        console.error(`Error in ${context}:`, error)

        // You can add more sophisticated error handling here, like displaying a notification to the user
    }

    // Getters
    const get = async (id: string) => {
        try {
            const project = await projectService.getProject(id)
            if (!project) {
                throw new Error(`Project ${id} not found`)
            }
            projects.set(id, project)

            project.revisions = project.revisions.filter(
                (revision: any): revision is IRevision => revision.outputVideoURL && revision.thumbnails[0]
            )

            return project
        } catch (error) {
            handleError(error, 'get')
        }
        return projects.get(id) as IProject
    }

    const has = async (id: string) => {
        try {
            if (!projects.has(id)) {
                const project = await projectService.getProject(id)
                if (!project) {
                    return false
                }
                projects.set(id, project)
                return true
            }
            return true
        } catch (error) {
            handleError(error, 'has')
            return false
        }
    }

    // Actions
    const update = async (projectId: string, project: Partial<IProject>) => {
        try {
            const updatedProject = await projectService.updateProject(projectId, project)
            if (updatedProject) {
                // projects.set(projectId, updatedProject)     // UNCOMMENT AFTER API GETS FIXED - GET and PUT return different projects
                console.log('updatedProject', updatedProject)
            }
            return updatedProject
        } catch (error) {
            handleError(error, 'update')
        }
    }

    const remove = async (projectId: string) => {
        try {
            const deletedProject = await projectService.deleteProject(projectId)
            if (deletedProject) {
                projects.delete(projectId)
                // projectPaginationStore.deleteItem(projectId)
            }
            return deletedProject
        } catch (error) {
            // const event = new CustomEvent('show-toast', {
            //     detail: {
            //         message: 'Error While Deleting Project',
            //         type: 'error'
            //     }
            // });
            // window.dispatchEvent(event);
            handleError(error, 'remove')
        }
    }

    const reset = async () => {
        const token = await getTokenSilently()
        projectService = new ProjectApiClient({
            auth: {
                accessToken: token as string,
            },
        })
    }
    return {
        init,
        get,
        has,
        update,
        remove,
        reset,
    }
      
})

export const useCompletedProjectPaginationStore = createPaginationStore<IProject>(
    'Batchproject',
    '_id',
    () => useAuth0Store().user,
    async (page, limit, ...args) => {
        const { getTokenSilently } = useAuth0Store()
        const token = await getTokenSilently({
            authorizationParams: {
                audience: `https://project-api.${CLUSTER}.inhausai-infra.services`,
            },
        })
       
        const projectService = new ProjectApiClient({
            auth: {
                accessToken: token as string,
            },
        })

        const { items, total } = await projectService.listProjects(page, limit, ...args)
        return { items, totalItems: total }
    }
)