import { defineStore } from 'pinia'
import { ref, reactive, computed } from 'vue'
// import { useAuth0Store } from '../auth0Store'


export const createPaginationStore = <T>(
    name: string,
    keyField: keyof T,
    user: any,
    fetch: (
        page: number,
        limit: number,
        filters?: { [key: string]: string | string[] },
        search?: string,
        dateRange?: string
    ) => Promise<{
        items: T[]
        totalItems: number
    }>
) =>
    defineStore(`pagination-${name}`, {
        state: () => {
            // 

         
            const _currentPage = ref<number>(0)
            const _pageSize = ref<number>(25)
            const _totalPages = ref<number>(0)
            const _totalItems = ref<number>(0)
            const documentType = ref<string>('')
            const _items = reactive<Map<string, T>>(new Map<string, T>())
            const _filters = reactive<{ [key: string]: string | string[] }>({})
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() + 1)
            const _dateRange = reactive<{ startDate: string; endDate: string }>({
                startDate: new Date('01.01.1970').toISOString(),
                endDate: currentDate.toISOString(),
            })
            const _search = ref<string>('')
            const _loading = ref<boolean>(false)

            // Getters
            const numberOfActiveFilters = computed(() => {
                var temp = 0

                if (user?.role === 'admin') {
                    if (_filters.workflowId) {
                        temp += 1
                    }
                    if (_filters.studios) {
                        temp += 1
                    }
                    if (_filters.artDirectorStatus || _filters.photographerStatus || _filters.qualityAssuranceStatus) {
                        temp += 1
                    }

                    if (_filters.priority) {
                        temp += 1
                    }
                } else {
                    if (_filters.workflowId) {
                        temp += 1
                    }
                    if (_filters.studios) {
                        temp += 1
                    }
                    if (_filters.artDirectorStatus) {
                        temp += 1
                    }
                    if (_filters.photographerStatus) {
                        temp += 1
                    }
                    if (_filters.qualityAssuranceStatus) {
                        temp += 1
                    }
                    if (_filters.priority) {
                        temp += 1
                    }
                }

                return temp
            })
            const currentPage = computed(() => _currentPage)
            const pageSize = computed(() => _pageSize)
            const totalPages = computed(() => _totalPages)
            const totalItems = computed(() => _totalItems)
            const filters = computed(() => _filters)
            const search = computed(() => _search)
            const dateRange = computed(() => _dateRange)
            const items = computed(() => Array.from(_items.values()))
            const isLoading = computed(() => _loading)
            const hasNextPage = computed(() => _currentPage.value < _totalPages.value - 1)
            const hasPreviousPage = computed(() => _currentPage.value > 0)

            // Actions
            const setCurrentPage = (page: number) => {
                _currentPage.value = page

                fetchItems()
            }
            const setPageSize = (size: number) => {
                _pageSize.value = size
                fetchItems()
            }
            const setFilters = (filters: { [key: string]: string | string[] }) => {
                Object.assign(_filters, filters)
                // console.log("setFilters",saveCurrentPage,filters)
                // if (!saveCurrentPage) {
                //     setCurrentPage(0)
                // } else {
                //     setCurrentPage(saveCurrentPage)
                // }
                console.log('setFilters', _filters)
                setCurrentPage(0)
            }
            const setSearch = (search: string) => {
                _search.value = search

                // localStorage.setItem('search', JSON.stringify(_search))
                setCurrentPage(0)
                // if (!saveCurrentPage) {

                // } else {
                //     setCurrentPage(saveCurrentPage)
                // }
            }
            const setTypeFilters = (filter: string, saveCurrentPage: number) => {
                documentType.value = filter

                // localStorage.setItem('documentType', JSON.stringify(documentType))
                setCurrentPage(0)
                // if (!saveCurrentPage) {

                // } else {
                //     setCurrentPage(saveCurrentPage)
                // }
            }
            const setDateRange = (dateRange: {startDate: String, endDate: String}) => {
                console.log('cgheck this out ')
                Object.assign(_dateRange, dateRange)

                setCurrentPage(0)
                // if (!saveCurrentPage) {

                // } else {
                //     setCurrentPage(saveCurrentPage)
                // }
            }
            const nextPage = async () => {
                if (hasNextPage.value) {
                    _currentPage.value++
                    localStorage.setItem('nextPage', JSON.stringify(_currentPage))
                    await fetchItems()
                }
            }
            const previousPage = async () => {
                if (hasPreviousPage.value) {
                    _currentPage.value--
                    localStorage.setItem('previousPage', JSON.stringify(_currentPage))
                    await fetchItems()
                }
            }
            const refresh = async () => {
                await fetchItems()
                
            }

            // Internal
            const fetchItems = async () => {
                _loading.value = true

                const { items, totalItems } = await fetch(
                    _currentPage.value,
                    _pageSize.value,
                    _filters,
                    documentType.value ? '_' + documentType.value : _search.value,
                    `${_dateRange.startDate}--${_dateRange.endDate}`
                )

                _items.clear()

                items.forEach((item) => {
                    if (!_items.has(item[keyField] as string)) {
                        _items.set(item[keyField] as string, item)
                    } else {
                        _items.set(item[keyField] as string, item)
                    }
                })

                _totalItems.value = totalItems
                _totalPages.value = Math.ceil(_totalItems.value / _pageSize.value)
                _loading.value = false
            }

            const getNumbersOfProjectsInStatus = async (filter: any) => {
                const { items, totalItems } = await fetch(0, 0, filter)

                return totalItems
            }

            const updateItem = (item: any) => {
                const key = item._id
                const existingItem = _items.get(key) // Assuming _id is used as the key
                if (!existingItem) {
                    console.warn(`Item with ID "${key}" not found in _items`)
                    return // Or handle non-existent item as needed
                } else {
                    _items.set(key, item)
                }
            }
            const deleteItem = (key: any) => {
                const existingItem = _items.get(key) // Assuming _id is used as the key
                if (!existingItem) {
                    console.warn(`Item with ID "${key}" not found in _items`)
                    return // Or handle non-existent item as needed
                } else {
                    _items.delete(key)
                }
            }
            const resetFilters = () => {
                _items.clear()
                _currentPage.value = 0
                _search.value = ''

                Object.keys(_filters).forEach((key) => {
                    delete _filters[key]
                })
                const currentDate = new Date()
                currentDate.setDate(currentDate.getDate() + 1)

                Object.assign(_dateRange, { startDate: new Date('01.01.1970').toISOString(), endDate: currentDate.toISOString() })
            }
            // const init = () => {
            //     JSON.parse(localStorage.getItem('totalItems'))
            //     _currentPage.value = JSON.parse(localStorage.getItem('currentPage'))
            //     _search.value = JSON.parse(localStorage.getItem('search'))
            // }

            // Return
            return {
                // Getters
                _totalItems,
                _search,
                _currentPage,
                _dateRange,
                _totalPages,
                _filters,
                // currentPage,
                // pageSize,
                // totalPages,
                // totalItems,
                filters,
                // search,
                // // dateRange,
                items,
                // isLoading,
                hasNextPage,
                hasPreviousPage,
                numberOfActiveFilters,

                // Actions
                setCurrentPage,
                setPageSize,
                setFilters,
                setSearch,
                setDateRange,
                nextPage,
                previousPage,
                refresh,
                setTypeFilters,
                updateItem,
                deleteItem,
                resetFilters,
                getNumbersOfProjectsInStatus,
                // fetchItemsOnMount,
            }
        },
        persist: { debug: true },
    })
