<script lang="ts">
export default {
    name: 'HeaderContent',
}
</script>
<script setup lang="ts">
import { Container, Button, Dropdown, Card, ButtonGroup } from '@inhaus-ai-limited/vue-components'
import HeroIcon from '@/components/HeroIcon.vue'
import { Routes } from '@/router/Routes'
import getCurrentRouteName from '@/utils/getRouteName'
import { computed } from 'vue'
import { useAuth0Store } from '@/stores/auth0Store'
import { useRefStore } from '@/stores/refStore'
import { useProjectPaginationStore } from '@/stores/project'

const projectPaginationStore = useProjectPaginationStore()
const refStore = useRefStore()
const auth0 = useAuth0Store()
const user = computed(() => auth0.user)
const isLoading = computed(() => auth0.isLoading)
const isAuthenticated = computed(() => auth0.isAuthenticated)

const login = () => auth0.loginWithRedirect()
const logout = () => {
    refStore.reset()
    projectPaginationStore.resetFilters()
    auth0.logout()
}
</script>

<template>
    <Container class="grid grid-cols-3">
        <Container class="flex flex-col justify-center">
            <Button
                class="float-left max-w-fit"
                variant="label"
                color="secondary"
                v-if="
                    isAuthenticated &&
                    !isLoading &&
                    $route.name !== 'artdirector' &&
                    $route.name !== 'projects' &&
                    $route.name !== 'quality-assurance' &&
                    $route.name !== 'unauthorized'
                "
                @click="$router.back()"
            >
                <HeroIcon icon="ChevronLeftIcon" class="w-20 h-20 rounded-sm inline-block" />
                <span class="text-Cse w-44 h-28 font-bold ml-[.5vw]"> Back</span>
            </Button>
        </Container>
        <Container class="flex flex-col justify-center mx-auto">
            <Button
                variant="label"
                color="secondary"
                class="flex-auto justify-center items-center"
                @click="
                    $router.push({
                        name: Routes.Home,
                        force: true,
                    })
                "
            >
                <img src="@/assets/logo.png" alt="InHaus.ai" class="w-94 h-25 mx-auto" />
            </Button>
        </Container>
        <Container class="flex flex-col justify-center">
            <ButtonGroup v-if="!isAuthenticated && !isLoading" class="flex my-auto justify-end h-fit space-x-[5px]">
                <Button
                    class="w-[100px] max-w-[120px] border-white border-[1px]"
                    variant="default"
                    size="md"
                    color="primary"
                    @click="login()"
                    >Log-in</Button
                >
            </ButtonGroup>
            <div v-else class="grid my-auto gap-[25px] justify-end h-fit">
                <Dropdown :fixed="true" class="min-w-[500px] [&>.absolute]:top-[50px]">
                    <template #trigger="{ toggle, isVisible }">
                        <Button size="lg" color="secondary" @click="toggle" class="space-x-10 !justify-end flex">
                            <span class="ml-2 text-gray-400 font-semibold">{{ user?.email }}</span>

                            <span class="ml-2 text-black font-semibold w-[80px] text-end">{{
                                getCurrentRouteName()
                            }}</span>
                            <HeroIcon
                                v-if="isVisible"
                                icon="ChevronUpIcon"
                                class="w-20 h-20 rounded-sm inline-block float-right"
                            />
                            <HeroIcon
                                v-else
                                icon="ChevronDownIcon"
                                class="w-20 h-20 rounded-sm inline-block float-right"
                            />
                        </Button>
                    </template>
                    <Card :square="true" :shadow="true" class="max-w-[200px] float-right">
                        <ul class="flex flex-col items pt-10">
                            <li v-if="user?.role !== 'admin'">
                                <Button
                                    textPosition="left"
                                    size="lg"
                                    color="secondary"
                                    :class="
                                        $route.name == Routes.Home ||
                                        $route.fullPath == '/admin/dashboard' ||
                                        $route.fullPath == '/photographer' ||
                                        $route.fullPath == '/artdirector'
                                            ? 'text-left text-black'
                                            : 'text-left text-gray-400'
                                    "
                                    @click="
                                        $router.push({
                                            name: Routes.Home,
                                        })
                                    "
                                >
                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Projects</span>
                                </Button>
                            </li>
                            <li v-if="user?.role === 'admin'">
                                <Button
                                    textPosition="left"
                                    size="lg"
                                    color="secondary"
                                    :class="
                                        $route.name == Routes.Projects
                                            ? 'text-left text-black'
                                            : 'text-left text-gray-400'
                                    "
                                    @click="
                                        $router.push({
                                            name: Routes.Projects,
                                        })
                                    "
                                >
                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Projects</span>
                                </Button>
                            </li>

                            <li>
                                <Button
                                    textPosition="left"
                                    size="lg"
                                    color="secondary"
                                    :class="
                                        $route.name == Routes.AdminJobs
                                            ? 'text-left text-black'
                                            : 'text-left text-gray-400'
                                    "
                                    @click="
                                        $router.push({
                                            name: Routes.AdminJobs,
                                        })
                                    "
                                >
                                    <HeroIcon icon="BriefcaseIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Jobs</span>
                                </Button>
                            </li>
                            <li v-if="user?.role === 'admin'">
                                <Button
                                    textPosition="left"
                                    size="lg"
                                    color="secondary"
                                    :class="
                                        $route.name == Routes.AdminDashboard ||
                                        $route.fullPath == '/admin/dashboard' ||
                                        $route.fullPath == '/photographer' ||
                                        $route.fullPath == '/artdirector'
                                            ? 'text-left text-black'
                                            : 'text-left text-gray-400'
                                    "
                                    @click="
                                        $router.push({
                                            name: Routes.AdminDashboard,
                                        })
                                    "
                                >
                                    <HeroIcon icon="DownloadIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Batch</span>
                                </Button>
                            </li>

                            <!--                            <li v-if="auth?.role === 'admin'">-->
                            <!--                                <Button-->
                            <!--                                    textPosition="left"-->
                            <!--                                    size="lg"-->
                            <!--                                    color="secondary"-->
                            <!--                                    class="text-left"-->
                            <!--                                    @click="-->
                            <!--                                        router.push({-->
                            <!--                                            name: Routes.QualityAssurance,-->
                            <!--                                        })-->
                            <!--                                    "-->
                            <!--                                >-->
                            <!--                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />-->
                            <!--                                    <span class="ml-10 font-medium">Quality Assurance</span>-->
                            <!--                                </Button>-->
                            <!--                            </li>-->

                            <li v-if="user?.role === 'admin'">
                                <Button
                                    textPosition="left"
                                    size="lg"
                                    color="secondary"
                                    :class="
                                        $route.name == Routes.AdminSettings
                                            ? 'text-left text-black'
                                            : 'text-left text-gray-400'
                                    "
                                    @click="
                                        $router.push({
                                            name: Routes.AdminSettings,
                                        })
                                    "
                                >
                                    <HeroIcon icon="CogIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Settings</span>
                                </Button>
                            </li>
                            <li v-if="user?.role == 'admin'">
                                <Button
                                    textPosition="left"
                                    size="lg"
                                    color="secondary"
                                    :class="
                                        $route.name == Routes.AdminReports
                                            ? 'text-left text-black'
                                            : 'text-left text-gray-400'
                                    "
                                    @click="
                                        $router.push({
                                            name: Routes.AdminReports,
                                        })
                                    "
                                >
                                    <HeroIcon icon="DocumentTextIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Reports</span>
                                </Button>
                            </li>
                            <li>
                                <Button
                                    textPosition="left"
                                    size="lg"
                                    color="secondary"
                                    class="text-left text-gray-400"
                                    @click="logout()"
                                >
                                    <HeroIcon icon="LogoutIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Logout</span>
                                </Button>
                            </li>
                        </ul>
                    </Card>
                </Dropdown>
            </div>
        </Container>
    </Container>
</template>
